.footer {
    font-family: $font-Alegreya-sans;
    font-size: $font-size-base;
    position: relative;
    &::before {
        position: absolute;
        content: "";
        background-image: url("/images/footer-image.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        top: 0;
        left: 0;
        width: 100%;
        height: 286px;
        @include media-breakpoint-up(xl) {
            height: 400px;
        }
    }
    .footer-top {
        background-color: $white;
        padding-top: 25%;
        padding-bottom: 70px;
        @include media-breakpoint-up(xl) {
            padding-top: 23%;
            padding-bottom: 80px;
        }

        .footer_text {
            h4,
            p {
                color: $base-4;
            }
            h4 {
                font-family: $font-Alegreya-sans;
                font-weight: 500;
                font-size: $h3-font-size;
            }
            p {
                margin-bottom: 0;
                font-weight: $font-weight-regular;
            }
        }
        .container-four-columns {
            .container-holder {
                .column {
                    margin-bottom: 35px;
                    @include media-breakpoint-up(sm) {
                        margin-bottom: 15px;
                    }
                    p {
                        line-height: 1.5 !important;
                    }
                }
                .column.one {
                    position: relative;
                    margin-bottom: 15px;
                    .logo_footer {
                        &.logo {
                            max-width: 290px;
                            margin: 20px 0 40px;

                            @include media-breakpoint-up(sm) {
                                margin: 0;
                            }
                            img {
                                @include media-breakpoint-up(sm) {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
                .column.two {
                    p,
                    a {
                        color: $base-3 !important;
                        font-weight: 700 !important;
                    }
                }
                .column {
                    &.one {
                        @include media-breakpoint-up(lg) {
                            flex: 0 0 34%;
                            max-width: 34%;
                        }
                    }
                    &.two {
                        @include media-breakpoint-up(lg) {
                            flex: 0 0 18%;
                            max-width: 18%;
                        }
                        @include media-breakpoint-up(xl) {
                            flex: 0 0 17%;
                            max-width: 17%;
                        }
                    }
                    &.three {
                        @include media-breakpoint-up(lg) {
                            flex: 0 0 23%;
                            max-width: 23%;
                        }
                        @include media-breakpoint-up(xl) {
                            flex: 0 0 20%;
                            max-width: 20%;
                        }
                    }
                    &.four {
                        @include media-breakpoint-up(lg) {
                            flex: 0 0 25%;
                            max-width: 25%;
                        }
                        @include media-breakpoint-up(xl) {
                            flex: 0 0 29%;
                            max-width: 29%;
                        }
                        .opening-hours {
                            display: flex;

                            span {
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }
    }
    .footer-socket {
        background-color: $white;
        padding: 0 0 40px;
        @include media-breakpoint-up(sm) {
            padding: 0 0 20px;
        }
        .column {
            &.navbar {
                padding: 0;
                @include media-breakpoint-up(sm) {
                    justify-content: center;
                }
                .footer_link {
                    .list {
                        .list-item {
                            @include media-breakpoint-up(md) {
                                margin-right: 20px;
                            }
                            .link {
                                color: $base-2;
                                font-size: $font-size-base_sm;
                                font-family: $font-Alegreya-sans;
                                font-weight: $font-weight-base;

                                &:hover {
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
				.page_block.vocabulary_term_list {
					margin-bottom: 0;
					margin-top:0.5rem;

					@include media-breakpoint-down(lg) {
						order: 4;
					}

					li {
						display: flex;

						a {
							font-size: 0;
							color: $base-3;

							i {
								font-size: $font-size-base * 1.25;
							}

							&:hover {
								color: $secondary;
							}
						}
					}
				}
}
