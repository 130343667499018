/* bootstrap > theme-colors
   ========================================================================== */
   $primary:    #FF8214 !default;
   $secondary:  #295871 !default;
   $tertiary:   #285770 !default;
   
   $base:       #545764;
   $body-color: $base !default;
   
   /* bootstrap > font
       ========================================================================== */
    @import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;500;700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,400&display=swap');
  
   $font-Alegreya-sans:  'Alegreya Sans', sans-serif;
   $font-Merriweather: 'Merriweather', serif;
   $font-awesome: "Font Awesome 5 Pro";
   
   $font-family-base: $font-Alegreya-sans !default;
   
   $font-weight-regular: 400;
   $font-weight-base: 500;
   
   $font-size-base: 1rem !default; //16px
   $font-size-base_sm: 0.875rem !default; //14px
   $font-size-base_xs: 0.813rem !default; //13px
   $font-size-base_xxs: 0.75rem !default; //12px
   
   $h1-font-size: $font-size-base * 2.25!default; //48px
   $h2-font-size: $font-size-base * 1.5 !default; //30px
   $h3-font-size: $font-size-base * 1.125 !default; //20px
   $h4-font-size: $font-size-base * 1.1875!default; //24px
   $h5-font-size: $font-size-base * 1.0625!default;
   $h6-font-size: $font-size-base_xs !default;
   
   /* bootstrap > typography
      ========================================================================== */
   $headings-font-family: $font-Merriweather !default;
   $headings-font-weight: 400;
   
   body.default {
      overflow-x: hidden;
   }
   
 