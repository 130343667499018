.main {
    .intro.content {
        padding: 50px 0 50px;

        @include media-breakpoint-up(sm) {
            padding: 80px 0 50px;
        }
        .container-default {
            .container-holder {
                text-align: center;
                .page_block.resource_image {
                    width: 315px;
                    height: 77px;
                    display: inline-block;
                    background: url("/images/home.svg") no-repeat top/cover;
                    img {
                        display: none;
                    }
                }
                h1 {
                    color: $tertiary;
                    text-align: center;
                    margin-top: $grid-gutter-width / 3;

                    @include media-breakpoint-down(sm) {
                        max-width: 300px;
                    }
                }
                p {
                    max-width: 615px;
                    margin: 0 auto $grid-gutter-width;
                    text-align: center;
                    @include media-breakpoint-down(sm) {
                        font-size: $font-size-base / 1.066 !important;
                    }
                }
                .intro_link.page_block.link {
                    @extend .btn-secondary;
                    &:not(:last-of-type) {
                        margin-bottom: $grid-gutter-width / 1.5;
                    }
                    @include media-breakpoint-up(sm) {
                        &:not(:last-of-type) {
                            margin-right: $grid-gutter-width / 1.5;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .home-blocks {
        overflow-x: hidden;
        padding: 0 0 ($grid-gutter-width / 1.2) 0;
        @include media-breakpoint-up(sm) {
            padding: 0 0 ($grid-gutter-width * 1.166) 0;
        }
        .container-default {
            padding: ($grid-gutter-width / 6) 0;
            @include media-breakpoint-up(sm) {
                padding: 0 ($grid-gutter-width / 1.5);
            }
            .container-holder {
                .grid.intro_home_blokken {
                    .item {
                        margin: 0 0 ($grid-gutter-width / 6) 0;
                        @include media-breakpoint-up(sm) {
                            padding: 0 ($grid-gutter-width / 15);
                            margin: 0;
                        }
                        .card {
                            border-radius: 0;
                            @include media-breakpoint-up(sm) {
                                border-radius: 8px;
                            }
                            &-img-overlay {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            &-caption {
                                flex: 0 0 auto;
                                max-width: 325px;
                                text-align: center;

                                .card-title-link {
                                    &:hover {
                                        color: $white;
                                        .card-title {
                                            text-shadow: 2px 2px 2px rgba($base, 0.5);
                                        }
                                    }
                                }
                                .card-title,
                                .card-subtitle {
                                    text-align: center;
                                }
                                .card-title {
                                    margin-bottom: $grid-gutter-width / 3;
                                    font-size: $h2-font-size !important;
                                    text-shadow: none;
                                }
                            }
                            &-buttons {
                                .card-btn {
									@extend .btn-primary;
									font-family: $font-family-base !important;
									cursor: auto !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .facilities {
        background: $base-3;
        padding: 55px 0 75px;
        color: $white;
        overflow-x: hidden;

        @include media-breakpoint-up(sm) {
            padding: 55px 0;
        }
        .container-default {
            .container-holder {
                h1 {
                    color: $white;
                    margin-bottom: $grid-gutter-width / 1.167;
                }
            }
        }
        .container-two-columns {
            .container-holder {
                .column {
                    p {
                        @include media-breakpoint-down(sm) {
                            font-size: $font-size-base / 1.066 !important;
                        }
                    }
                }
                .column.two {
                    .page_block.link {
                        @extend .btn-secondary-bg;
                    }
                }
            }
        }
        .container-one-column.facilities_blocks {
            padding-top: 100px;
			padding-bottom: 75px;

            .container-holder {
                .column {
                    .grid {
                        .item {
                            &:nth-child(2) {
								@include media-breakpoint-up(lg) {
                                	margin-top: 55px;
								}
                            }
                            &:nth-child(even) {
                                @include media-breakpoint-up(lg) {
                                    margin-top: $grid-gutter-width * 1.84;
                                }
                            }
                            .card {
                                background: transparent;
                                border: none;
                                border-radius: 8px;
                                text-decoration: none;
                                box-shadow: 6px 6px 12px $box-shadow;
                                height: auto !important;
                                &-image {
                                    position: relative;

                                    &::after {
                                        content: url("/images/polygon.svg");
                                        display: block;
                                        position: absolute;
                                        bottom: 0;
                                        width: 100%;
                                        height: 20px;
                                        z-index: 1;
                                        text-align: center;
                                    }
                                    .card-img {
                                        height: 375px;
                                        object-fit: cover;
                                    }
                                }
                                &-body {
                                    flex: 0 0 auto !important;
                                    border-radius: 0 0 8px 8px;
                                    .card-caption {
                                        display: flex;
                                        justify-content: center;

                                        .card-title {
                                            font-size: $font-size-base * 1.125 !important;
                                            color: $secondary;
                                            margin: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .icons-class {
        padding: ($grid-gutter-width * 2.33) 0 0;
        overflow-x: hidden;
        @include media-breakpoint-up(sm) {
            padding: ($grid-gutter-width * 1.5) 0 0;
        }
        .container-default.container-text {
            margin-bottom: $grid-gutter-width * 2.34;
            .container-holder {
                .grid {
                    .item {
                        .card.icon {
                            text-decoration: none;
                            border: 0;
                            .card-image {
                                font-size: 0;
                                img {
                                    width: 70px;
                                    height: 70px;
                                    object-fit: contain;
                                }

                                i {
                                    font-size: $font-size-base * 4.375;
                                    color: $black;
                                }
                            }
                            .card-body {
                                .card-caption {
                                    .card-title {
                                        font-family: $font-family-base !important;
                                        font-size: $h4-font-size !important;
                                        color: $base-3 !important;
                                        font-weight: 700 !important;
                                    }
                                    .card-title,
                                    .card-description {
                                        text-align: center;
                                    }
                                    .card-description {
                                        min-height: 100%;
                                        max-height: 100%;
                                        max-width: 280px;
                                        margin: auto;
                                        color: $base-4;
                                        font-weight: 400;
                                        &::after {
                                            content: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .container-default.container-blocks {
            .container-holder {
                h1 {
                    color: $tertiary;
                    max-width: 845px;
                    margin: 0 auto ($grid-gutter-width * 2);
                    text-align: center;
                }
            }
        }
    }
}
&.home {
    .main {
        .newsletter-section {
            @extend .newsletter;
            margin: ($grid-gutter-width / 1.2) 0 0 !important;
        }
    }
}
&.two_column_page {
    .main {
        .container {
            .container-holder {
                .column {
                    ul {
                        @extend .custom-list;
                    }
                    .article_content {
                        ul {
                            @extend .custom-list;
                        }
                    }
                    .page_block.resource_image {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}
