.mini-sab {
    position: relative;
    z-index: 1;
    padding: 0;
    background: none;
    .container-default {
        @extend .d-flex;
        @extend .justify-content-center;
        .container-holder {
            border-radius: 8px;
            background: $base-3;
            border: 1px solid $base-2;
            box-shadow: 6px 6px 12px $box-shadow;
            @include media-breakpoint-down(md) {
                margin-top: -70px;
                padding: 15px 20px 20px 20px;

            }
            @include media-breakpoint-up(lg) {
                position: absolute;
                bottom: 100%;
                margin-bottom: 20px;
                padding: 20px 30px 25px 30px;
                min-width: 776px;
            }

            /* heading */
            .heading {
                margin-bottom: 5px;
                color: $white;
                font-size: 18px;
            }
        }
    }
}

&.home {
    .mini-sab {
        .container-default {
            .container-holder {
                @include media-breakpoint-up(lg) {
                    margin-bottom: 40px;
                }
            }
        }
    }
}
