.main {
	.content.detail,
	.content.overview {
		padding: ($grid-gutter-width * 2) 0;
		overflow-x: unset;
		.info,
		.column.one {
			.btn-back {
				display: flex;
				position: relative;
				font-size: $font-size-base / 1.066;
				color: $tertiary;
				font-weight: 700;
				text-decoration: none;
				padding-left: $grid-gutter-width / 1.5;
				padding-bottom: $grid-gutter-width / 2;
				&:hover {
					&::before {
						left: -5px;
					}
				}
				&::before {
					content: "\f177";
					position: absolute;
					left: 0;
					transition: all 0.3s;
				}
			}
			.extra {
				color: $base-4;
				font-size: $font-size-base / 1.066;
			}
			h1,
			h2,
			h3 {
				color: $tertiary !important;
				text-align: left;
			}
			h2,
			h3 {
				font-size: $font-size-base * 1.625;
			}
			p {
				color: $base-4;
				text-align: left;
				padding: 0;
			}
			.accommodation-properties {
				&__item {
					padding-left: 0;
					&::before {
						content: none;
					}
				}
			}
			ul {
				@extend .custom-list;
				li:has(ul) {
					padding-left: 0 !important;

					ul {
						margin-left: 1.5rem;
						margin-top: 10px;
						li {
							padding-left: 0.95rem;
							&::before {
								content: "\f111";
								font-size: 8px;
								top: 0;
								transform: none;
							}
						}
					}
					&::before {
						top: unset;
						left: unset;
						transform: unset;
						display: inline-block !important;
						position: relative !important;
						margin-right: 0.5rem;
					}
				}
			}
		}
		.info {
			@include media-breakpoint-up(sm) {
				margin-bottom: 0 !important;
			}
		}
		.info-extra {
			margin: 0 0 30px;
			@include media-breakpoint-up(sm) {
				margin: $grid-gutter-width * 1.67 0 0;
			}
			.gallery {
				@extend .gallery-section;
			}
		}
		.column.two {
			margin-top: $grid-gutter-width;
			@include media-breakpoint-up(sm) {
				margin-top: $grid-gutter-width / 6;
			}
			.gallery {
				@extend .gallery-section;
			}
		}
		.container.intro {
			margin-bottom: 0;
			@include media-breakpoint-up(sm) {
				margin-bottom: $grid-gutter-width * 1.67;
			}
		}
	}
	.newsletter-section {
		@extend .newsletter;
		margin: 0 0 ($grid-gutter-width * 2) !important;
	}
	.content.info {
		padding: ($grid-gutter-width * 2.33) 0;
		@include media-breakpoint-up(sm) {
			padding: ($grid-gutter-width * 3.33) 0 ($grid-gutter-width * 2.33);
		}
		.container {
			.container-holder {
				.column {
					h1,
					h2,
					h3 {
						color: $tertiary !important;
					}
					h2 {
						font-size: $font-size-base * 1.625;
					}
					h2,
					h3 {
						margin-top: $grid-gutter-width * 1.33;
					}
					p {
						color: $base-4;
					}
					ul {
						@extend .custom-list;
					}
					.gallery {
						margin: ($grid-gutter-width / 1.5) -
							($grid-gutter-width / 3) 0 -
							($grid-gutter-width / 3);
						@extend .gallery-section;
					}
				}
			}
		}
	}
}
