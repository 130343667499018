@import './0_config/vendor-variables';
@import '~framework/assets/scss/Dev/0_config/vendor-variables';
@import '~framework/assets/scss/Dev/setup';
@import './0_config/website-variables';
@import '~framework/assets/scss/Dev/0_config/website-variables';
@import '~framework/assets/scss/Dev/1_vendor/bootstrap';

body.default {
	@import '~framework/assets/scss/Dev/base';

	@import './2_layout/header';
	@import './2_layout/eyecatcher';
	@import './2_layout/footer';
	@import './2_layout/mini-sab';
	@import './2_layout/section';
	@import './2_layout/base';

	@import './3_component/button';
	@import './3_component/card';

	@import './4_page_block/collection';

	@import './6_theme/w3-searchbook-widget.scss';
}

@import './6_theme/tommy.booking';
