.w3-vue-booking-wrapper.w3-searchbook-widget {
    .w3-filter-collection {
        .filters {
            .filter-element {
                &.filter-context-date {
                    order: 1;
                }
            }
        }
    }
}