.btn {
    display: inline-flex;
    align-items: center;
    padding: ($grid-gutter-width / 6) ($grid-gutter-width / 1.5);
    font-size: $font-size-base / 1.066 !important;
    font-weight: 700;
    border-radius: 3px;
    color: $white;
    text-decoration: none;
    transition: 0.3s;
    box-shadow: 0px 3px 6px $box-shadow;

    &-primary {
        @extend .btn;
        color: $white;
        background-color: $primary;
        border-color: $primary;
        padding: ($grid-gutter-width / 6) ($grid-gutter-width * 1.33) ($grid-gutter-width / 6) ($grid-gutter-width / 1.5) !important;
        position: relative;

        &::after {
            position: absolute;
            top: 50%;
            right: 20px;
            content: "\F178";
            transform: translateY(-50%);
            font-family: $font-awesome;
            font-weight: 500;
        }   
        &:active {
            color: $white;
            background: $tertiary;
            border-color: $tertiary; 
        }
        &:visited {
            color: $white;
            background: $primary;
            border-color: $primary; 
        }
        &:hover {
            color: $white;
            background: $base-3;
            border-color: $base-3;
        }
    }
    &-outline-primary {
        @extend .btn;
        color: $primary;
        background-color: $white;
        border-color: $primary;
        padding: ($grid-gutter-width / 6) ($grid-gutter-width * 1.33) ($grid-gutter-width / 6) ($grid-gutter-width / 1.5) !important;
        position: relative;

        &::after {
            position: absolute;
            top: 50%;
            right: 20px;
            content: "\F178";
            transform: translateY(-50%);
            font-family: $font-awesome;
            font-weight: 500;
        }
        &:active {
            color: $tertiary;
            background: $white;
            border-color: $tertiary; 
        }
        &:visited {
            color: $primary;
            background: $white;
            border-color: $primary; 
        }
        &:hover {
            color: $base-3;
            background: $white;
            border-color: $base-3;
        }
    }
    &-secondary {
        @extend .btn;
        color: $secondary;
        background-color: transparent;
        border-color: $base-3;
        padding: ($grid-gutter-width / 6) ($grid-gutter-width * 1.33) ($grid-gutter-width / 6) ($grid-gutter-width / 1.5) !important;
        position: relative;

        &::after {
            position: absolute;
            top: 50%;
            right: 20px;
            content: "\F178";
            transform: translateY(-50%);
            font-family: $font-awesome;
            font-weight: 500;
        }
        &:hover,
        &:active {
            color: $primary;
            background: $white;
            border: $border-width solid $primary;
        }
    }
    &-outline-secondary {
        @extend .btn;
        color: $white;
        background-color: $secondary;
        border-color: $base-3;
        padding: ($grid-gutter-width / 6) ($grid-gutter-width * 1.33) ($grid-gutter-width / 6) ($grid-gutter-width / 1.5) !important;
        position: relative;

        &::after {
            position: absolute;
            top: 50%;
            right: 20px;
            content: "\F178";
            transform: translateY(-50%);
            font-family: $font-awesome;
            font-weight: 500;
        }
        &:hover,
        &:active {
            color: $primary;
            background: $white;
            border: $border-width solid $primary;
        }
    }
    &-secondary-bg {
        @extend .btn;
        color: $white;
        background-color: transparent;
        border-color: $white;
        padding: ($grid-gutter-width / 6) ($grid-gutter-width * 1.33) ($grid-gutter-width / 6) ($grid-gutter-width / 1.5) !important;
        position: relative;

        &::after {
            position: absolute;
            top: 50%;
            right: 20px;
            content: "\F178";
            transform: translateY(-50%);
            font-family: $font-awesome;
            font-weight: 500;
        }
        &:hover,
        &:active {
            color: $white;
            background: $tertiary;
            border: $border-width solid $white;
        }
    }
}
