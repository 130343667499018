p {
	font-size: $h5-font-size !important;
	font-weight: 400 !important;
	line-height: 1.8 !important;
}

h1 {
	@include media-breakpoint-down(sm) {
		font-size: $font-size-base * 1.875 !important;
	}
}
h2 {
	@include media-breakpoint-down(sm) {
		font-size: $font-size-base * 1.375 !important;
	}
}
h3 {
	@include media-breakpoint-down(sm) {
		font-size: $font-size-base * 1.125 !important;
	}
}
.newsletter {
	background: $tertiary;
	padding: ($grid-gutter-width * 1.67) 0 ($grid-gutter-width * 3);
	box-shadow: 6px 6px 12px $box-shadow;
	.container-default {
		.container-holder {
			h1,
			p {
				color: $white;
			}
			h1 {
				font-size: $font-size-base * 1.875;
				@include media-breakpoint-down(sm) {
					font-size: $h2-font-size !important;
				}
			}
			p {
				a {
					color: $base-2;
					text-decoration: underline;

					&:hover {
						color: $white;
					}
				}
			}
		}
	}
}
.custom-list {
	list-style-type: none;
	padding-left: 0;
	margin-top: $grid-gutter-width;
	li {
		position: relative;
		padding-left: $grid-gutter-width / 1.2;
		margin-bottom: 10px;
		font-size: $font-size-base;

		&::before {
			position: absolute;
			left: 0;
			top: 0;
			content: "\f00c";

			font-size: $font-size-base;
			font-family: $font-awesome;
			font-weight: 600 !important;
			color: $base-3;
		}
	}
}
.gallery-section {
	.gallery-item {
		&:hover {
			transform: none;
		}
		.gallery-link {
			.gallery-img {
				border-radius: 8px;
			}
		}
	}
}
