//section scss
.content.overview {
	.container-holder {
		h1 {
			color: $tertiary;
			text-align: center;
			+ p {
				max-width: 600px;
				margin: 0 auto;
				padding: 15px 0 45px;
				line-height: 30px;
				color: $body-color;
				font-family: $font-family-base;
				text-align: center;
				font-weight: $font-weight-regular;
			}
		}
		////////////////////////-------------------------------------------------------
		//card scss
		.page_block {
			&.grid {
				.item {
					@include media-breakpoint-up(xl) {
						@include make-col(4);
					}

					.card {
						box-shadow: 6px 6px 12px $box-shadow;
						border-radius: 8px;

						.card-image {
							position: relative;
							border-bottom: ($border-width * 5) solid $white;
							&::after {
								content: url("/images/polygon.svg");
								display: block;
								position: absolute;
								bottom: 0;
								width: 100%;
								height: 20px;
								z-index: 1;
								text-align: center;
							}
							.card-image-link {
								height: 100%;
								.card-img {
									width: 100%;
									height: 100%;
								}
							}
						}
						.card-body {
							background-color: $secondary;
							padding: 30px 20px;
							.card-caption {
								.card-title {
									font-family: $font-Merriweather;
									font-size: $font-size-base * 1.5;
									margin-bottom: 31px;
									color: $white;
								}
								.card-subtitle {
									color: white;
								}
								.card-title .card-subtitle {
									display: none;
								}
								.card-description {
									max-height: 100%;
									font-weight: 400;
									&::after {
										content: none;
									}
									.card-description-content {
										font-size: $font-size-base;
										line-height: 30px;
										font-family: $font-family-base;
										color: $white;
									}
									.list {
										&.list-icons {
											.list-item {
												color: $white;
												display: flex;
												align-items: center;
												.icon {
													color: $base-2;
													i {
														font-weight: 700;
													}
												}
												.label {
													font-size: $h5-font-size;
													font-weight: 400;
												}
											}
										}
									}
									p {
										color: #fff;
									}
								}
							}
							.card-buttons {
								margin-top: 40px;
								.card-btn {
									position: relative;
									color: $card-btn-color;
									font-size: $font-size-base * 0.938;
									font-family: $font-family-base;
									border: none;
									background: none;
									box-shadow: none;
									padding: 0;
									font-weight: bold;
									text-decoration: underline;
									&:hover {
										&::after {
											right: -30px;
										}
									}

									&::after {
										font-family: $font-awesome;
										font-size: $font-size-base;
										content: "\f178";
										position: absolute;
										right: -25px;
										top: 0;
										width: 20px;
										height: 20px;
										transition: all 0.3s;
									}
								}
							}
						}
					}
				}
			}
			/*&.search_book {
                .accommodation-list {
                    .accommodation-list-item {
                        .list-item-content {
                            border-radius: 8px;
                            .list-item-image {
                                position: relative;
                                border-bottom: ($border-width * 5) solid $white;
                                border-top-left-radius: 8px;
                                border-top-right-radius: 8px;
                                &::after {
                                    content: url("/images/polygon.svg");
                                    display: block;
                                    position: absolute;
                                    bottom: 0;
                                    width: 100%;
                                    height: 20px;
                                    z-index: 1;
                                    text-align: center;
                                }
                                img {
                                    border-top-left-radius: 8px;
                                    border-top-right-radius: 8px;
                                }
                            }
                        }
                        .list-item-container {
                            background-color: $secondary;
                            padding: 30px 20px;
                            border-bottom-left-radius: 8px;
                            border-bottom-right-radius: 8px;
                            .list-item-body {
                                .list-item-caption {
                                    .list-item-title {
                                        max-height: 100%;
                                        margin-bottom: 31px;
                                        .list-item-link {
                                            font-family: $font-Merriweather;
                                            font-size: $font-size-base * 1.5;
                                            color: $white;
                                            font-weight: $font-weight-regular;
                                        }
                                    }
                                    .list-item-subtitle {
                                        display: none;
                                    }
                                }
                                .list-item-description {
                                    max-height: 100%;
                                    font-weight: 400;
                                    &::after {
                                        content: none;
                                    }
                                    .card-description-content {
                                        font-size: $font-size-base;
                                        line-height: 30px;
                                        font-family: $font-family-base;
                                        color: $white;
                                    }
                                }
                                .list-item-extra {
                                    .list-item-btn {
                                        position: relative;
                                        color: $card-btn-color;
                                        font-size: $font-size-base * 0.938;
                                        font-family: $font-family-base;
                                        border: none;
                                        background: none;
                                        padding: 0;
                                        font-weight: bold;
                                        text-transform: inherit;
                                        &:hover {
                                            &::after {
                                                right: -30px;
                                            }
                                        }

                                        &::after {
                                            font-family: $font-awesome;
                                            font-size: $font-size-base;
                                            content: "\f178";
                                            position: absolute;
                                            right: -25px;
                                            top: 0;
                                            width: 20px;
                                            height: 20px;
                                            transition: all 0.3s;
                                        }
                                    }
                                    .list {
                                        &.list-icons {
                                            min-height: 75px;
                                            &::after {
                                                height: 0;
                                            }
                                            .list-item {
                                                color: $white;
                                                display: flex;
                                                align-items: center;
                                                .icon {
                                                    color: $card-btn-color;
                                                    i {
                                                        font-weight: bold;
                                                    }
                                                }
                                                .label {
                                                    font-family: $font-family-base !important;
                                                    font-size: $h5-font-size;
                                                    font-weight: 400;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .results {
                    &.results-list {
                        .accommodation-list {
                            .accommodation-list-item {
                                .list-item-content {
                                    &,
                                    .list-item-image {
                                        &,
                                        .list-item-link {
                                            &,
                                            img {
                                                height: 100%;
                                                border-top-right-radius: 0;
                                            }
                                        }
                                    }
                                    .list-item-container {
                                        border-bottom-left-radius: 0;
                                        border-top-right-radius: 8px;
                                        .list-item-body {
                                            @include media-breakpoint-up(lg) {
                                                justify-content: space-between;
                                            }
                                            .list-item-extra {
                                                @include media-breakpoint-up(lg) {
                                                    flex: 0 0 30%;
                                                    max-width: 30%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            */
		}
	}
}

.icons-class {
	.container-blocks {
		.container-holder {
			.masonry.card-columns {
				@include media-breakpoint-up(lg) {
					-moz-column-count: 3;
					column-count: 3;
					page-break-inside: avoid;
					-moz-column-break-inside: avoid;
					break-inside: avoid;
				}
				.item {
					@include media-breakpoint-up(xl) {
						@include make-col(12);
					}
					@include media-breakpoint-up(lg) {
						&:nth-child(3) {
							padding-top: 100px;
						}
					}

					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4) {
						.card {
							.card-body {
								background: $base-3 !important;
							}
						}
					}
					.card {
						box-shadow: 6px 6px 12px $box-shadow;
						border-radius: 8px;
						.card-image {
							position: relative;
							border-bottom: ($border-width * 5) solid $white;
							&::after {
								content: url("/images/polygon.svg");
								display: block;
								position: absolute;
								bottom: 0;
								width: 100%;
								height: 20px;
								z-index: 1;
								text-align: center;
							}
							.card-img {
								width: 100%;
								height: 100%;
							}
						}
						.card-body {
							background: $secondary !important;
							padding: 30px 20px;
							.card-caption {
								.card-title {
									font-family: $font-Merriweather;
									font-size: $font-size-base * 1.5;
									margin-bottom: 31px;
									color: $white;
								}

								.card-subtitle {
									color: $white;
								}
								.card-description {
									max-height: 100%;
									font-weight: 400;
									&::after {
										content: none;
									}
									.card-description-content {
										font-size: $font-size-base;
										line-height: 30px;
										font-family: $font-family-base;
										color: $white;
									}
									.list {
										&.list-icons {
											.list-item {
												color: $white;
											}
										}
									}
								}
							}
							.card-buttons {
								display: none;
							}
							//This is commented as there is no button on cards.. If button is available in future please remove commented code for button style
							// .card-buttons {
							//     margin-top: 40px;
							//     .card-btn {
							//         position: relative;
							//         color: $card-btn-color;
							//         font-size: $font-size-base * 0.938;
							//         font-family: $font-family-base;
							//         border: none;
							//         background: none;
							//         padding: 0;
							//         font-weight: bold;
							//         text-decoration: underline;
							//         &:hover {
							//             &::after {
							//                 right: -30px;
							//             }
							//         }

							//         &::after {
							//             font-family: $font-awesome;
							//             font-size: $font-size-base;
							//             content: "\f178";
							//             position: absolute;
							//             right: -25px;
							//             top: 0;
							//             width: 20px;
							//             height: 20px;
							//             transition: all 0.3s;
							//         }
							//     }
							// }
						}
					}
				}
			}
		}
	}
}
