.header {
	@include media-breakpoint-up(xl) {
		position: sticky;
	}
	.header-main {
		.container-navbar {
			.container-holder {
				.navbar {
					padding: ($grid-gutter-width / 2.5) ($grid-gutter-width / 1.875);

					.page_block.logo {
						max-width: 140px;
						@media screen and(max-width: 375px) {
							max-width: 100px;
						}
						@include media-breakpoint-up(sm) {
							max-width: 161px;
							margin: ($grid-gutter-width / 10) 0;
						}
						@include media-breakpoint-down(lg) {
							order: 2;
						}
					}

					.navbar-toggler {
						@include media-breakpoint-down(lg) {
							order: 1;
							background: $secondary;
							color: $white;
							margin-right: 10px;
							.navbar-toggler-icon {
								i {
									font-size: $font-size-base_sm;
								}
							}
							.navbar-toggler-label {
								font-size: $font-size-base;
								font-weight: 700;
								text-transform: uppercase;
								margin-left: $grid-gutter-width / 6;
							}
						}
					}

					&-collapse {
						@include media-breakpoint-down(lg) {
							order: 5;
						}

						.navbar-nav {
							.nav-item {
								.nav-link {
									color: $tertiary;
									font-size: $h5-font-size;
									font-weight: 700;

									&:hover {
										color: $base-3;
									}
								}

								&:not(:last-child) {
									@include media-breakpoint-up(xl) {
										margin-right: $grid-gutter-width / 3;
									}
								}

								.dropdown-toggle {
									position: relative;

									&::after {
										content: "\f078";
										position: absolute;
										right: 0;
										display: block;
										font-family: $font-awesome;
										font-weight: 900;
										transform: translateY(-50%);
										top: 50%;
										font-size: $font-size-base_xxs;
										height: 16px;
										border: none !important;
										@include media-breakpoint-up(xl) {
											right: -5px;
										}
									}

									@include media-breakpoint-down(lg) {
										&[aria-expanded="true"] {
											&::after {
												content: "\f068";
											}
										}
										&::after {
											margin-right: 3px;
											content: "\F067";
											font-family: $font-awesome;
											font-size: $font-size-base_xs;
											font-weight: 700;
											border: none;
										}
									}
								}

								.dropdown-menu {
									left: -10%;
									border: none;
									@include media-breakpoint-down(sm) {
										padding: 0;
									}
									@include media-breakpoint-up(xl) {
										border: 1px solid $base-1;
										border-radius: 6px;
										box-shadow: 0px 3px 6px $box-shadow;
									}

									.nav-item {
										margin: 0;

										.nav-link {
											font-weight: 400;
											@include media-breakpoint-up(sm) {
												padding: ($grid-gutter-width / 7.5) ($grid-gutter-width / 1.5);
											}

											&:hover {
												color: $primary !important;
											}
										}
									}

									@include media-breakpoint-up(xl) {
										&:before,
										&:after {
											content: "";
											display: block;
											height: 0;
											left: 50%;
											position: absolute;
											width: 0;
										}
										&:before {
											border-left: 8px solid transparent;
											border-right: 8px solid transparent;
											border-bottom: 6px solid $base-1;
											margin-left: -5px;
											top: -6px;
											z-index: 4;
										}
										&:after {
											border-left: 6px solid transparent;
											border-right: 6px solid transparent;
											border-bottom: 5px solid $white;
											margin-left: -3px;
											top: -5px;
											z-index: 5;
										}
									}
								}
							}
						}
					}

					.page_block.site_switcher {
						display: flex;
						flex-grow: 1;
						justify-content: flex-end;
						margin-right: $grid-gutter-width / 1.5;

						@include media-breakpoint-down(lg) {
							order: 3;
						}

						.navbar-nav {
							.nav-item {
								.dropdown-toggle {
									position: relative;
									text-transform: uppercase;
									color: $base-3;

									&::after {
										content: "\f078";
										position: absolute;
										right: -12px;
										display: block;
										font-family: $font-awesome;
										transform: translateY(-50%);
										top: 50%;
										font-size: $font-size-base_xxs;
										height: 16px;
										border: none !important;

										@include media-breakpoint-up(xl) {
											right: -5px;
										}
									}
								}

								.dropdown-menu {
									position: absolute;
									left: 0;
									transform: translateX(-50%);
									border: 1px solid $base-1;
									border-radius: 6px;
									box-shadow: 0px 3px 6px $box-shadow;

									@include media-breakpoint-up(xl) {
										transform: translateX(-40%);
									}

									&:before,
									&:after {
										content: "";
										display: block;
										height: 0;
										left: 50%;
										position: absolute;
										width: 0;
									}

									&:before {
										border-left: 8px solid transparent;
										border-right: 8px solid transparent;
										border-bottom: 6px solid $base-1;
										margin-left: -5px;
										top: -6px;
										z-index: 4;
									}

									&:after {
										border-left: 6px solid transparent;
										border-right: 6px solid transparent;
										border-bottom: 5px solid $white;
										margin-left: -3px;
										top: -5px;
										z-index: 5;
									}

									.dropdown-item {
										color: $tertiary;

										&:hover {
											color: $primary;
											background: transparent;
										}
									}
								}
							}
						}
					}

					.page_block.vocabulary_term_list {
						margin-bottom: 0;

						@include media-breakpoint-down(lg) {
							order: 4;
						}

						li {
							display: flex;

							a {
								font-size: 0;
								color: $base-3;

								i {
									font-size: $font-size-base * 1.25;
								}

								&:hover {
									color: $secondary;
								}
							}
						}
					}
				}
			}
		}
	}

	.dropdown-menu {
		min-width: 80px;
	}
}

@media screen and(max-width: 500px) {

	.navbar-nav > li.nav-item.last .nav-link {
		color: #FF8214 !important;
	}

}
@media screen and(min-width: 450px) {

	.navbar-nav > li.nav-item.last .nav-link {
		color: #fff !important;
		background-color: #FF8214;
		border-color: #FF8214;
		padding: 10px 20px 10px 20px !important;
		position: relative;
		border-radius: 4px;
		margin-left: 10px;
	}

}

