.eyecatcher {
    &.large {
		height: 70vh;
		min-height: 400px;
		z-index: -1;

        .eyecatcher-container {
            .container-holder {
                position: relative;

                &::after {
                    content: url('/images/arrow.svg');
                    display: none;
                    position: absolute;
                    bottom: -20px;
                    width: 35px;
                    height: 20px;
                    text-align: center;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            .owl-carousel {
				height: 70vh;
				min-height: 400px;

                .item {
                    position: relative;
					height: 70vh !important;
					min-height: 400px;

                    &::after {
                        content: "";
                        position: absolute;
                        background: linear-gradient(180deg, transparent, $black);
                        bottom: 0;
                        left: 0;
                        height: 120px;
                        width: 100%;
                        opacity: 0.7;
                    }
                    .owl-container {
                        display: flex;
                        justify-content: center;
                        max-width: 550px;
                        position: absolute;
                        //bottom: 200px;
                        margin: auto;
                        left: 0;
                        right: 0;

                        .owl-caption {
                            .owl-title {
                                text-align: center;
                                font-size: $font-size-base * 1.875;
                                @include media-breakpoint-up(lg) {
                                    font-size: $h1-font-size;
                                }
                            }
                        }
                    }
                }
            }
        }

		// vimeo_video
		.vimeo_video {
			position: relative;
			height: 70vh;
			min-height: 400px;
			overflow: hidden;

			iframe {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100vw;
				height: 100vh;
				max-width: none;
				transform: translate(-50%, -50%);
				@media (min-aspect-ratio: 16/9) {
					/* height = 100 * (9 / 16) = 56.25 */
					height: 56.25vw;
				}
				@media (max-aspect-ratio: 16/9) {
					/* width = 100 / (9 / 16) = 177.777777 */
					width: 177.78vh;
				}
			}
		}

		// video-title
		.video-title {
			z-index: 100;
			position: absolute;
			top: 0;
			left: 15px;
			right: 15px;
			bottom: 0;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			max-width: 550px;
			margin: 0 auto;
			color: $white;
			font-size: $font-size-base * 1.875 !important;
			line-height: 1.2 !important;
			text-align: center;
			font-family: $headings-font-family;
			text-shadow: 2px 2px 2px rgba($base, 0.5);
			@include media-breakpoint-up(lg) {
				font-size: $h1-font-size !important;
			}
		}

    }
}
